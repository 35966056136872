import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  app: "corrente",
  appExecutive: "Cinnux",
  production: true,
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  fallback: "chat" as const,
  appLogo: "assets/img/stimulus.svg",
  socketUrl: "https://stimulus-socket.cinnux.com/",
  passphrase: "1234",
  videoHost: "socket.cinnux.com",
  socketPath: "/socket-chat",
  allowAwayMessage: true,
  languages: ["de", "en"],
  firebase: {
    apiKey: "AIzaSyDv-ooj49YaWW0P4X3tiIwqYspOPvHf43I",
    authDomain: "cinnux-ec90b.firebaseapp.com",
    databaseURL: "https://cinnux-ec90b.firebaseio.com",
    projectId: "cinnux-ec90b",
    storageBucket: "cinnux-ec90b.appspot.com",
    messagingSenderId: "103216955993",
    appId: "1:103216955993:web:295228694baf85bf12e748",
  },
};
